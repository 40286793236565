.navigation {
	width: 100%;
	height: 100%;
}

.navigation.active {
	height: 100%;
}

.nav-links {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;
	font-size: 1rem;
}

.nav__group {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
	height: 100%;
	padding: 0;
	margin: 0;
}

.nav__group li {
	height: 100%;
	display: flex;
	align-items: center;
}

.navigation a,
.navigation button {
	border: none;
	background-color: transparent;
	color: var(--text-secondary);
	text-decoration: none;
	display: flex;
	align-items: center;
	height: 100%;
	box-sizing: border-box;
	padding: 4px 0;
}

.navigation a.active {
	padding-bottom: 0;
	border-bottom: solid 4px var(--green);
}

.navigation a:hover:not(.active) {
	padding-bottom: 0;
	border-bottom: solid 4px #6b7280;
}

.toggle-button {
	display: none;
	position: absolute;
	top: 1.125rem;
	right: 1rem;
}
.bar {
	display: block;
	width: 30px;
	height: 3.5px;
	margin: 5px auto;
	transition: all 200ms ease-in-out;
	-webkit-transition: all 200ms ease-in-out;
	background-color: var(--text);
	border-radius: 5px;
}

@media (max-width: 1279px) {
	.nav-links {
		font-size: 14px;
	}
}

@media (max-width: 760px) {
	.navigation {
		height: 0;
	}
	.toggle-button {
		display: block;
	}
	.nav-links {
		margin: .5rem 0 0 0;
	}
	.nav-links.active {
		visibility: visible;
		display: flex;
		background-color: var(--background);
		max-height: fit-content;
		opacity: 100%;
	}
	.nav-links,
	.navigation a {
		visibility: hidden;
		width: 100%;
		opacity: 0;
		transition: all 200ms ease-in-out;
	}
	.navigation a:hover:not(.active) {
		padding: 0;
		border: none;
		color: var(--green-dark);
	}

	.nav-links a {
		transition: all 100ms ease-in-out;
	}
	.nav-links.active,
	.nav-links.active a {
		visibility: visible;
		opacity: 100%;
	}
	.navigation a.active {
		border: none;
		color: var(--green);
	}

	.navigation a {
		padding: 0;
		width: 100%;
		flex-direction: column;
		justify-content: center;
	}

	.nav-links,
	.nav-links ul {
		flex-direction: column;
		width: 100%;
		height: fit-content;
		align-items: stretch;
	}
	.nav__group li {
		height: 4rem;
		justify-content: center;
	}

	.toggle-button.active .bar:nth-child(2) {
		opacity: 0;
	}

	.toggle-button.active .bar:nth-child(1) {
		-webkit-transform: translateY(8.5px) rotate(45deg);
		-ms-transform: translateY(8.5px) rotate(45deg);
		-o-transform: translateY(8.5px) rotate(45deg);
		transform: translateY(8.5px) rotate(45deg);
	}

	.toggle-button.active .bar:nth-child(3) {
		-webkit-transform: translateY(-8.5px) rotate(-45deg);
		-ms-transform: translateY(-8.5px) rotate(-45deg);
		-o-transform: translateY(-8.5px) rotate(-45deg);
		transform: translateY(-8.5px) rotate(-45deg);
	}
}