.book-list {
	list-style: none;
	padding: 0;
	width: 80%;
	margin: 0;
}

.button {
	margin: .25rem 0;
}

.button.top {
	margin: .25rem 0 1.5rem 0;
}
@media (max-width: 760px) {
	.book-list {
		width: auto;
	}
}
