.heading {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	justify-items: center;
	width: 80%;
}

.select {
	width: fit-content;
	border-radius: 5px;
	margin-right: auto;
}

.search {
	margin-left: auto;
	justify-content: flex-end;
	display: flex;
	column-gap: 1rem;
	align-items: center;
}
.search button {
	margin: 0;
}

.search-bar {
	height: 38px;
	border-radius: 9999px;
	padding: 0 1rem;
	border: solid 1px var(--border);
}

@media (max-width: 1190px) {
	.heading {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
	}
	.select {
		grid-row: 3;
		width: 100%;
	}
    .search {
        width: 100%;
        justify-content: center;
    }
    .search input {
        width: 100%;
    }
    .search :no-button {
        width: auto;
    }
}

@media (max-width: 650px) {
	.heading {
		width: 100%;
	}
	.select {
		width: 100%;
	}
}

@media (max-width: 450px) {
	.search {
		flex-direction: column;
		gap: 1rem;
	}
	.search button {
		width: 100%;
	}
}
