.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 3rem 0 3rem;
    min-height: 65vh;
}

@media (max-width: 400px) {
    .layout {
        margin: 0 1rem 0 1rem;
    }
}