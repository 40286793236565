.header {
	z-index: 2;
	background-color: var(--background);
	box-shadow: 0px 4px 4px hsla(0, 0%, 80%, 0.251);
	max-width: 100%;
	height: 72px;
	padding: 0 1rem 0 5.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
}

.logo {
	z-index: 2;
	height: 40px;
	margin: 0 3rem 0 0;
}

.backdrop {
	display: none;
	background-color: #00000080;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 2;
}

@media (max-width: 760px) {
	.header {
		padding: 0;
		height: 64px;
		flex-direction: column;
		justify-content: flex-start;
		position: relative;
		width: 100%;
	}
	.logo {
		margin-top: 0.5rem;
		margin: 0.5rem 0 0 2.25rem;
	}
	.backdrop {
		display: block;
	}
}

@media (max-width: 500px) {
	.logo {
		margin-right: 5rem;
	}
}
