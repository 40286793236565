.spinner {
	border: 8px solid transparent;
	border-top: 8px solid var(--green);
	border-radius: 50%;
	width: 45px;
	height: 45px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 760px) {
	.spinner {
		width: 30px;
		height: 30px;
		border-width: 6px;
	}
}
