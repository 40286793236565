:root {
	--purple: hsl(309, 100%, 25%);
	--purple-bright: hsl(309, 100%, 35%);
	--green: hsl(98, 100%, 31%);
	--green-dark: hsl(100, 95%, 25%);
	--text: #111827;
	--text-highlight: #1f2937;
	--text-accent: #4b5563;
	--text-secondary: #374151;
	--border: hsl(220, 9%, 65%);
	--background: #ffffff;
	--background-gray: #f7f7f7;
	--error: #df3d3d;
	--error-dark: hsl(0, 72%, 49%);
	--warn: #e3ab4b;
	--warn-dark: hsl(38, 73%, 54%);
}
/* Mobile-Small: 360px */
/* Mobile: 760px */
/* Tablet: 980 */

@font-face {
	font-family: "Myniad";
	src: url(./assets/fonts/Myniad-Regular.woff2);
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Myniad";
	src: url(./assets/fonts/Myniad-Light.woff2);
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: "Myniad";
	src: url(./assets/fonts/Myniad-Semibo.woff2);
	font-weight: 700;
	font-style: normal;
}

*,
html {
	font-family: "Myniad", sans-serif;
}
body {
	margin: 0;
	width: 100%;
	min-height: 100vh;
	color: var(--text-highlight);
	font-size: 14px;
	background-color: var(--background-gray);
}

h1 {
	font-size: 1.875rem;
	/* font-size: 30px; */
	font-weight: 700;
	color: var(--green);
}

h2 {
	font-size: 1.25rem;
	font-weight: 700;
	color: var(--green);
}
h3 {
	font-size: 1.125rem;
	font-weight: 700;
	color: var(--text-highlight);
}

p {
	font-size: 1rem;
}

b {
	font-weight: 700;
}

input[type="checkbox"] {
	accent-color: var(--green);
}

.secondary {
	color: var(--text-secondary);
	font-weight: 200;
}
.accent {
	color: var(--text-accent);
	font-weight: 200;
}

a {
	color: var(--green);
}

.centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
