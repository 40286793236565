.footer {
	border-top: solid 1px rgb(229, 231, 235);
	margin-top: 6rem;
	padding: 4rem 1rem 6rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.advertising {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.social-media {
	display: flex;
	flex-direction: row;
	margin: 2rem 0.5rem;
}

.social-media a:first-child {
	margin: 0 0.5rem 0 -0.5rem;
}

.social-media a {
	margin: 0rem 0.5rem;
}

.social-media {
	max-height: 20px !important;
}

.social-media-link {
	max-height: 20px;
}

.logo {
	max-height: 2rem;
}

.logos {
	display: flex;
	max-height: 2rem;
	gap: 1rem;
}

.library-logo {
	max-height: 2.25rem;
}

.legal a {
	color: var(--text-accent);
	text-decoration: none;
	font-size: 0.75rem;
	background: transparent;
	padding: 0;
	margin: 0;
}

.legal a:hover {
	background: none;
}

.legal {
	display: flex;
	gap: 1rem;
	align-items: center;
}

@media (max-width: 360px) {
	.legal {
		flex-direction: column;
	}
	.logos {
		flex-direction: column;
		max-height: none;
	}
	.logo {
		max-height: 2rem;
		margin-right: 2.5rem;
	}
	.library-logo {
		max-height: 2.25rem;
	}
	.advertising {
		gap: 1rem;
	}
}
