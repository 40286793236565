a.button {
	text-decoration: none;
}

input:disabled,
textarea:disabled,
select:disabled {
	border-color: var(--border);
	color: var(--border);
	background-color: var(--background);
	opacity: 1;
}

input,
textarea {
	border: solid 1px var(--border);
	outline-color: var(--green);
	padding: 0.5rem;
	border-radius: 5px;
}

select {
	border: solid 1px var(--border);
	padding: 0.5rem;
	outline-color: var(--green);
	border-radius: 5px;
}

.error {
	color: var(--error);
}

.success {
	color: var(--green);
	font-weight: 200;
}

.info {
	color: var(--text-accent);
	font-weight: 200;
	font-style: italic;
}

input.error {
	border-color: var(--error);
	outline-color: var(--error);
}

.warn {
	border-color: var(--error) !important;
	color: var(--error) !important;
}

.inline {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.inline.gap {
	gap: 1rem;
}

.no-margin {
	margin: 0 !important;
}

.bear {
	display: none;
}

@media (max-width: 650px) {
	.inline.res650 {
		flex-direction: column;
	}
}

@media (max-width: 360px) {
	.inline.res360 {
		flex-direction: column;
	}
}