.list-item {
	margin: 0 0 1.25rem 0;
	width: 100%;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none;
	color: var(--text);
	background-color: var(--background);
	box-shadow: 0px 4px 4px hsla(0, 0%, 80%, 0.3);
	width: 100%;
	border-radius: 5px;
}
.cover-group {
	height: 125px;
	position: relative;
}
.cover {
	border-radius: 5px 0 0 5px;
	height: 125px;
}
.cover.notavailable {
	filter: saturate(0);
}
.blocked {
	width: 85px;
	opacity: 75%;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}
.cover-placeholder {
	height: 125px;
	width: 80px;
	max-width: 100px;
	box-shadow: 0px 4px 4px hsl(0deg 0% 80% / 30%);
	border-radius: 5px 0 0 5px;
	background-color: #eee;
}

.infos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	margin: 0 2rem;
	column-gap: 2rem;
}
.book-infos {
	display: flex;
	flex-direction: column;
}
.isbn {
	font-size: 0.75rem;
	font-weight: 200;
	color: var(--text-secondary);
}
.title {
	margin: 0.125rem 0 0.25rem 0;
}
.author {
	font-weight: 200;
}
.publisher-infos {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.published-year {
	font-weight: 200;
}

@media (max-width: 340px) {
	.cover-group {
		height: 125px;
	}
	.cover {
		height: 125px;
	}
	.infos {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.title {
		font-size: 16px;
	}
}

@media (max-width: 280px) {
	.cover-group {
		height: 110px;
	}
	.cover {
		height: 110px;
	}
	.blocked {
		width: 60px;
	}
	.author {
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}