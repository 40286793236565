.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--green);
	color: var(--background);
	text-decoration: none;
	padding: 0.65rem 1.25rem 0.65rem 1.25rem;
	height: 38px;
	margin: 0 0 1rem 0;
	border: none;
	border-radius: 9999px;
	transition: all 200ms ease-in-out;
	position: relative;
}

.button:hover {
	cursor: pointer;
	background-color: var(--green-dark);
}

.button:hover .arrow {
	position: relative;
	animation: arrow__hover 1s infinite;
}

.button:disabled {
	background-color: #ccc;
	transition: none;
}
.button:disabled:hover {
	cursor: not-allowed;
	transform: none;
}
.button__alt:disabled {
	background-color: transparent;
	border-color: #bbb;
	color: #bbb;
}
.button__alt:disabled:hover {
	background-color: transparent;
	border-color: #bbb;
	color: #bbb;
}
.button:disabled:hover .arrow {
	animation: none;
}

.button__arrow {
	box-shadow: 0px 4px 4px hsla(0, 0%, 80%, 0.3);
}
.button__arrow:hover {
	transform: scale(1.05);
}
.button__alt {
	display: flex;
	flex-direction: row;
	background-color: transparent;
	color: var(--green-dark);
	padding: 0.65rem 1.25rem;
	border: solid 1px var(--green-dark);
}
.button__alt:hover {
	cursor: pointer;
	border-color: var(--green);
	background-color: transparent;
	color: var(--green);
}
.button__alt:hover .arrow__alt {
	fill: var(--green);
}

svg.arrow {
	margin: 0 0 0 0.75rem;
}
.arrow {
	fill: var(--background);
}
.arrow__alt {
	fill: var(--green-dark);
}

.number {
	position: absolute;
	top: -0.5rem;
	right: .5rem;
	max-width: 1.25rem;
	height: 1.25rem;

	border-radius: 50%;
	background-color: var(--purple);

	display: flex;
	align-items: center;
	justify-content: center;
}

.number__span {
	position: absolute;
	top: 3px;
}

@keyframes arrow__hover {
	0%,
	100% {
		left: 0;
	}
	50% {
		left: 1rem;
	}
}
